









































































































































































































































import Vue from 'vue';
import EmptyHint from '@/components/workspace/EmptyHint.vue';
import Auth from '@/libs/auth';
import FolderList from '@/components/workspace/FolderList.vue';
import WorkspaceObjectApi from '@/apis/WorkspaceObjectApi';
import GuidedTour from '@/views/GuidedTour.vue';
import OpenGuidedTourBtn from '@/components/OpenGuidedTourBtn.vue';
import GuidedTourItem from '@/components/GuidedTourItem.vue';
import { WorkspaceFolderModel, WorkspaceObjectType, WorkspaceObjectModel } from '@/apis/models/WorkspaceObjectModel';
import WorkspaceHandler from '@/handlers/WorkspaceHandler';
import Breadcrumb from '@/components/workspace/Breadcrumb.vue';
import WorkspaceObjectItem from '@/components/test_maker/WorkspaceObjectItem.vue';
import MovePositionModal from '@/components/workspace/MovePositionModal.vue';
import UpdateWorkspaceObjectNameModal from '@/components/member_group_maker/UpdateWorkspaceObjectNameModal.vue';
import { QuizEventPreviewModel } from '@/apis/models/QuizEventModel';
import QuizEventHandler from '@/handlers/QuizEventHandler';

export enum Visibility {
  ALL,
  SENT,
  SCHEDULED,
  NOT_SCHEDULED,
}

export default Vue.extend({
  components: {
    // GuidedTour,
    // OpenGuidedTourBtn,
    // GuidedTourItem,
    EmptyHint,
    FolderList,
    Breadcrumb,
    WorkspaceObjectItem,
    MovePositionModal,
    UpdateWorkspaceObjectNameModal,
  },
  data() {
    return {
      selection: [] as QuizEventPreviewModel[],
      selectModeOpen: false,
      searchString: '',
      Visibility,
      visibility: Visibility.ALL,
      showGuidedTour: true,

      sentList: [] as QuizEventPreviewModel[],
      scheduledList: [] as QuizEventPreviewModel[],
      notScheduledList: [] as QuizEventPreviewModel[],
    };
  },
  computed: {
    isLoading(): boolean {
      return this.$store.state.isLoading;
    },
    currentFolder(): WorkspaceFolderModel | null {
      return this.$store.state.currentFolder;
    },
    cardDisplayMode(): string { // 'list-mode', 'pic-mode'
      return this.$store.state.cardDisplayMode;
    },
    subfolders(): WorkspaceFolderModel[] {
      if (this.currentFolder) {
        const arr = this.currentFolder.children.filter((object) => object.metadata.type === WorkspaceObjectType.Folder);
        const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });
        return arr.sort((a, b) => collator.compare(a.metadata.name.toUpperCase().trim(), b.metadata.name.toUpperCase().trim())) as WorkspaceFolderModel[];
      }
      return [];
    },
    showGuidedTourItem() {
      if (this.currentFolder) {
        const path = `/manager/workspace/quizevents/local/${this.currentFolder.uuid}`;
        if (this.showGuidedTour && this.$route.path === path) {
          return true;
        }
      }
      return false;
    },
    workspaceObjects(): QuizEventPreviewModel[] {
      if (this.currentFolder) {
        const workspaceObjectsList = this.currentFolder.children.filter((object) => object.metadata.type === WorkspaceObjectType.QuizEvent) as QuizEventPreviewModel[];
        return this.sortList(workspaceObjectsList); // tag: 全部
      }
      return [];
    },
    // ----------------------tag分類顯示在這裡被決定
    visibleList(): QuizEventPreviewModel[] {
      switch (this.visibility) {
        case Visibility.ALL:
          return this.workspaceObjects;
        case Visibility.SENT:
          return this.sentList;
        case Visibility.SCHEDULED:
          return this.scheduledList;
        case Visibility.NOT_SCHEDULED:
          return this.notScheduledList;
        default:
          return [] as QuizEventPreviewModel[];
      }
    },
    // ----------------------搜尋功能使用的地方
    trimSearchString(): string {
      // 修掉輸入的空白
      const string = this.searchString.trim();
      return string;
    },
    searchList(): QuizEventPreviewModel[] {
      const result = [] as QuizEventPreviewModel[];
      this.visibleList.forEach((element) => {
        const listName = element.metadata.name;
        const inputName = this.trimSearchString;
        const indexOf = listName.toUpperCase().indexOf(inputName.toUpperCase());
        if (indexOf >= 0) {
          result.push(element);
        }
      });
      return result;
    },
    filteredMethodList(): QuizEventPreviewModel[] {
      if (this.trimSearchString !== '') {
        return this.searchList;
      }
      return this.visibleList;
    },
  },
  watch: {
    '$route.params.folderId': {
      handler(folderId) {
        if (folderId) {
          WorkspaceHandler.loadFolder(folderId);
          this.selectInit();
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    Auth.loginCheck();
  },
  methods: {
    setShowGuidedTour(btn: boolean) {
      this.showGuidedTour = btn;
    },
    sortList(workspaceObjectsList: QuizEventPreviewModel[]) {
      const sortedList = [] as QuizEventPreviewModel[]; // 集中處
      const scheduledList = [] as QuizEventPreviewModel[]; // 排程發送中
      const sentList = [] as QuizEventPreviewModel[]; // 已發送完畢
      const notScheduledList = [] as QuizEventPreviewModel[]; // 尚未安排

      // 分類------
      workspaceObjectsList.forEach((item: QuizEventPreviewModel) => {
        const sentNum = item.PushedQuizGroupList.length;
        if (!item.scheduledTime && !sentNum) {
          notScheduledList.push(item); // 尚未安排
        } else if (!item.scheduledTime && sentNum > 0) {
          sentList.push(item); // 已發送完畢
        } else {
          scheduledList.push(item); // 排程發送中
        }
      });

      // 排序------
      const collator = new Intl.Collator('en', { numeric: true, sensitivity: 'base' });
      // 1. 排"排程中"的順序
      scheduledList.sort((a: QuizEventPreviewModel, b: QuizEventPreviewModel) => {
        if (a.scheduledTime !== b.scheduledTime) {
          if (a.scheduledTime < b.scheduledTime) {
            return -1;
          }
          if (a.scheduledTime > b.scheduledTime) {
            return 1;
          }
          return 0;
        }
        return collator.compare(a.metadata.name.toUpperCase().trim(), b.metadata.name.toUpperCase().trim());
      });
      this.scheduledList = scheduledList; // tag: 排程發送中
      // 2. 排"已發送完畢"的順序
      sentList.sort((a: QuizEventPreviewModel, b: QuizEventPreviewModel) => {
        const dateA = a.PushedQuizGroupList[a.PushedQuizGroupList.length - 1].scheduledTime;
        const dateB = b.PushedQuizGroupList[b.PushedQuizGroupList.length - 1].scheduledTime;
        if (dateA !== dateB) {
          if (dateA > dateB) {
            return -1;
          }
          if (dateA < dateB) {
            return 1;
          }
          return 0;
        }
        return collator.compare(a.metadata.name.toUpperCase().trim(), b.metadata.name.toUpperCase().trim());
      });
      this.sentList = sentList; // tag: 已發送完畢
      // 3. 按名稱排序"尚未安排"的類別
      notScheduledList.sort((a: QuizEventPreviewModel, b: QuizEventPreviewModel) => collator.compare(a.metadata.name.trim(), b.metadata.name.trim()));
      this.notScheduledList = notScheduledList; // tag: 尚未安排

      // 照順序放入"集中處"------
      // 1. 把"排成發送中"放入 集中處
      scheduledList.forEach((item: QuizEventPreviewModel) => {
        sortedList.push(item);
      });
      // 2. 再把"已發送完畢"放入 集中處
      sentList.forEach((item: QuizEventPreviewModel) => {
        sortedList.push(item);
      });
      // 3. 最後再把"尚未安排"放入 集中處
      notScheduledList.forEach((item: QuizEventPreviewModel) => {
        sortedList.push(item);
      });

      return sortedList;
    },
    reload() {
      if (this.currentFolder) {
        // reload folder
        WorkspaceHandler.loadFolder(this.currentFolder.uuid);
      }
      this.selectInit();
    },
    selectInit() {
      this.selectModeOpen = false;
      this.selection = [];
      this.searchString = '';
    },
    toggleSelectMode() {
      this.selectModeOpen = !this.selectModeOpen;
      if (!this.selectModeOpen) {
        this.selection = [];
      }
    },
    toggleCardDisplayMode() {
      if (this.cardDisplayMode === 'list-mode') {
        this.$store.commit('changeCardDisplayMode', 'pic-mode');
      } else {
        this.$store.commit('changeCardDisplayMode', 'list-mode');
      }
    },
    async toggleSelection(object: QuizEventPreviewModel) {
      const index = this.selection.findIndex((item) => item.uuid === object.uuid);

      if (index === -1) {
        this.selection.push(object);
      } else {
        this.selection.splice(index, 1);
      }
    },
    async moveCards() {
      this.$modal.show('move-position-modal', {
        currentFolder: this.currentFolder,
      });
    },
    async moveCardsSubmit(selectedFolderId: string) {
      console.log(selectedFolderId);
      this.$store.commit('updateLoading', true);

      const request = [] as Promise<void>[];
      // this.selection.forEach((object: WorkspaceObjectModel) => {
      //   if (this.currentFolder) {
      //     request.push(WorkspaceObjectApi.move(object.uuid, selectedFolderId));
      //   }
      // });

      for (let i = 0; i < this.selection.length; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        await WorkspaceObjectApi.move(this.selection[i].uuid, selectedFolderId);
        console.log(`(${i + 1}/${this.selection.length})`);
      }

      await Promise.all(request);
      // reload folder
      this.visitFolder(selectedFolderId);
      this.$modal.hide('move-position-modal');
      this.selectInit();
      this.$store.commit('updateLoading', false);
    },
    removeCards() {
      this.$modal.show('dialog', {
        title: '確定刪除嗎?',
        text: '刪掉就不會再回來囉',
        buttons: [
          {
            title: '取消',
            handler: () => {
              this.$modal.hide('dialog');
            },
          },
          {
            title: '確定',
            handler: () => {
              this.$modal.hide('dialog');
              this.removeManyGroups();
            },
          },
        ],
      });
    },
    shareCards() {
      this.$modal.show('manage-share-mapping-modal', {
        sourceObjectId: this.selection[0].uuid,
      });
      this.selectInit();
    },
    enterGroupPreview() {
      const object = this.selection[0];
      if (object.metadata.type === 'WorkspaceQuizEvent') {
        this.$store.commit('changeQuizEvent', object);
        this.$router.push('/manager/quizevent-preview');
      }
    },
    enterGroup() {
      const object = this.selection[0];
      if (object.metadata.type === 'WorkspaceQuizEvent') {
        this.$store.commit('changeQuizEvent', object);
        this.$router.push('/manager/quizevent-maker');
      }
    },
    async removeManyGroups() {
      this.$store.commit('updateLoading', true);
      const request = [] as Promise<void>[];
      // this.selection.forEach((object: WorkspaceObjectModel) => {
      //   request.push(c);
      // });
      for (let i = 0; i < this.selection.length; i += 1) {
        // eslint-disable-next-line no-await-in-loop
        await WorkspaceObjectApi.remove(this.selection[i].uuid);
        console.log(`(${i + 1}/${this.selection.length})`);
      }
      await Promise.all(request);
      this.selectInit();
      if (this.currentFolder) {
        // reload folder
        WorkspaceHandler.loadFolder(this.currentFolder.uuid);
      }
      this.$store.commit('updateLoading', false);
    },
    async removeGroupCard(object: WorkspaceObjectModel) {
      await WorkspaceObjectApi.remove(object.uuid);

      if (this.currentFolder) {
        // reload folder
        WorkspaceHandler.loadFolder(this.currentFolder.uuid);
      }
    },
    visitFolder(folderId: string) {
      const currentFolderId = this.$store.state.currentFolder.uuid;
      if (folderId !== currentFolderId) {
        // change params.folderId to switch folder
        this.$router.push({
          params: {
            folderId,
          },
        });
      }
    },
  },
});
